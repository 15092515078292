.sliderGallery img {
    width: 100%;
    max-width: 100%;
    max-height: 400px;
    object-fit: cover;
    background: #000;
}

.slick-prev, .slick-next {
    top: 36%;
}

.slick-prev {
    left: 20px;
    z-index: 9;
}

.slick-next {
    right: 30px;
}

.slick-prev:before, .slick-next:before {
    font-size: 36px;
    opacity: 1;
}

.downloadImg {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 10px;
}

.boxInfoPhoto {
    display: flex;
}

.boxInfoPhoto p {
    margin-right: 20px;
}

p.categoryImg {
    color: #e00d06;
    font-weight: 600;
}

.downloadImg a, .downloadImg a:hover, .downloadImg a:focus {
    color: #df0700;
    font-weight: 700;
    text-decoration: none;
}

.downloadImg a svg {
    font-size: 27px;
    margin-right: 5px;
}

@media screen and (max-width: 768px ){
    .slick-prev, .slick-next {
        display: none;
    }
}

a#allImages, a#allImages:hover, a#allImages:focus {
    text-decoration: none;
    color: #3a3a3a;
    font-weight: bold;
    font-size: 20px;
    display: table;
    margin: 0 auto;
}