.boxMidias .slick-prev:before, .boxMidias .slick-next:before {
    font-family: 'slick';
    font-size: 25px;
    color: #3a3a3a;
}

.boxMidias .slick-prev, .boxMidias .slick-next {
    top: 50%;
}

.boxMidias .slick-prev {
    left: -20px;
    z-index: 9;
}

.boxMidias .slick-next {
    right: -15px;
}

.sliderMidia {
    padding: 0 10px;
}

.sliderMidia a, .sliderMidia a:hover, .sliderMidia a:focus {
    color: #3a3a3a;
    font-size: 15px;
    display: table;
    margin-top: 10px;
    text-decoration: none;
}

.sliderMidia a svg {
    color: #ff0000;
    font-size: 22px;
    margin-top: -4px;
}

.sliderMidia .post {
    font-size: 15px;
    margin-top: 10px;
}

@media screen and (max-width: 768px) {
    .boxMidias .slick-prev, .boxMidias .slick-next {
        display: none !important;
    }
}

a#allVideos, a#allVideos:hover, a#allVideos:focus {
    text-decoration: none;
    color: #3a3a3a;
    font-weight: bold;
    font-size: 20px;
    display: table;
    margin: 20px auto 0;
}