.socialShareBtns {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}

.socialShareBtns button {
    background-color: transparent;
    border: none;
    width: 35px;
    height: 35px;
    margin: 8px;
}

.socialShareBtns button svg {
    font-size: 20px;
    color: #df0700;
}