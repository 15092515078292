.slick-slide img {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    
}

.sliderTop img {
    height: 400px;
}

.boxSlider h3 {
    font-size: 27px;
    font-weight: bold;
    line-height: 36px;
    margin: 13px 0px 7px;
}

.boxSlider p {
    margin-bottom: 10px;
    font-size: 19px;
    color: #3a3a3a;
}

.slick-list {
    margin-bottom: 25px;
}

.slick-dots {
    position: absolute;
    text-align: left;
}

.slick-dots li {
    margin: 0 10px;
    width: auto;
    height: auto;
}

.slick-dots li:first-child {
    margin: 0 10px 0 0;
}

.slick-dots li button:before {
    display: none;
}

.slick-dots li.slick-active button {
    opacity: 1;
    background-color: #ff0000;
}

.slick-dots li button {
    width: 35px;
    height: 10px;
    opacity: .25;
    background-color: #000;
}

.slick-dots li button:hover {
    opacity: 1;
}

@media screen and (max-width: 768px) {
    .sliderRight{
        margin-bottom: 80px;
    }
}