.noticeScroll {
    position: relative;
    margin: 25px 0;
    border-top: solid 2px #f7f7f7;
    padding: 12px 0;
    height: 52px;
    overflow: hidden;
}
.noticeScroll p {
    white-space: nowrap;
    font-size: 16px;
    font-weight: 600;
    padding-left: 30px;
}

.noticeScroll p a {
    display: flex;
}

div#lastNotices {
    background: #df0700;
    width: 170px;
    padding: 10px 0;
    position: absolute;
    top: 0;
    left: -10px;
    z-index: 9;
    text-align: center;
    font-size: 20px;
    font-weight: 900;
    text-transform: uppercase;
}

.spanColor {
    color: #df0700;
    font-size: 13px;
    float: left;
    display: table;
    margin-top: 2px;
    margin-right: 5px;
    font-weight: 400;
}

.boxSliderHeader p {
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
    padding-left: 30px;
}

@media screen and (max-width: 768px) {
    div#lastNotices {
        width: 135px;
        left: 0px;
        font-size: 17px;
    }
}