.newsSingleTitle {
    text-align: center;
    margin-top: 10px;
    text-transform: uppercase;
    color: #3a3a3a;
    font-weight: 700;
}

.newsSingleSubtitle {
    text-align: center;
    margin-top: 10px;
    color: #3a3a3a;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 20px;
}

.titleMoreNews {
    margin-top: 45px;
    margin-bottom: 25px;
}

.newsSingleImage {
    width: 100%;
    margin-top: 10px;
}