.boxScroll {
    height: 400px;
    overflow-y: scroll;
}

.dateSchedule {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #df0700;
    padding-bottom: 5px;
    padding-right: 15px;
}

.dateSchedule p {
    color: #3a3a3a;
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 0;
}

.dateSchedule button.showCalendar {
    color: #df0700;
    font-weight: 700;
    text-decoration: none;
    background: transparent;
    border: none;
}

.dateSchedule button.showCalendar svg {
    font-size: 23px;
    margin-right: 5px;
    margin-top: -5px;
}

.boxSchedule {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
}

.boxSchedule p {
    margin-bottom: 0;
}

p.hour {
    font-size: 14px;
    font-weight: 900;
    color: #ff0000;
}

p.subject {
    color: #181818;
    font-weight: 600;
    font-size: 15px;
}

p.place, p.mayor {
    font-size: 15px;
}

p.description {
    font-size: 14px;
    margin-top: 15px;
}

.react-calendar {
    position: absolute;
    top: 30px;
    right: 0px;
    z-index: 99;
}

@media(max-width: 575px){
    .react-calendar {
        right: 15px;
    }
}