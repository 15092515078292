@import '../../styles/colors';

header {
    padding: 20px 0;
}

header img {
    width: 100%;
    max-width: 195px;
    margin-top: 10px;
}

.mediaroom-title {
    letter-spacing: -1px;
    font-size: 40px;
    font-weight: 700;
    color: $textColor;
    text-align: center;
}

.header-col-center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.header-col-end {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
}

.search-bar {
    width: 100%;
    display: flex;
}

.search-bar input {
    flex: 1 1;
    border: 1px solid #363636;
    padding: 10px;
    border-radius: 0;
    outline: none;
}

.search-bar button {
    background: transparent;
    border: none;
    outline: none;
}

.search-bar svg {
    font-size: 32px;
}

button#linkSite {
    border: 2px solid #f7f7f7;
    padding: 10px;
    border-radius: 3px;
    background: transparent;
    float: right;
}

button#linkSite img {
    width: 100%;
    max-width: 106px;
    margin-top: 0;
}

button#linkSite p {
    margin-bottom: 0;
    font-size: 14px;
    text-align: center;
    font-weight: 600;
    color: #3a3a3a;
}

.headerFixed {
    position: fixed;
    top: 0;
    transform: translateY(-180%);
    left: 0;
    background-color: #fff;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    width: 100%;
    padding: 10px;
    z-index: 999;
    display: none;
    box-shadow: 0 1px 1px rgba(0,0,0,0.05), 
  0 2px 2px rgba(0,0,0,0.05), 
  0 4px 4px rgba(0,0,0,0.05), 
  0 8px 8px rgba(0,0,0,0.05), 
  0 16px 16px rgba(0,0,0,0.05), 
  0 32px 32px rgba(0,0,0,0.05);
}

.headerFixed img {
    width: 95px;
}

.headerFixed .mediaroom-title {
    font-size: 27px;
    margin: 0;
}

.headerFixed .search-bar {
    width: 80%;
}

@media(max-width: 575px){
    header img {
        margin: 0 auto;
        display: flex;
    }
    
    .mediaroom-title {
        font-size: 18px;
    }

    .search-bar {
        width: 90%;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .header-col-end {
        align-items: center;
    }
}