footer {
    background: #252525;
    text-align: center;
    padding-top: 35px;
    margin-top: 65px;
}

footer h5 {
    color: #fff;
    font-weight: 600;
    text-align: left;
    margin-bottom: 15px;
}

footer ul {
    list-style: none;
    padding: 0;
    text-align: left;
}

footer ul li {
    margin: 4px 0px;
}

footer ul li p {
    color: #fff;
    margin-bottom: 0;
    font-size: 15px;
}

footer ul li a, footer ul li a:hover, footer ul li a:focus {
    color: #fff;
    text-decoration: none;
    font-size: 15px;
}

.midiasFooter {
    margin-top: 75px;
    margin-bottom: 35px;
}

.midiasFooter a {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    text-decoration: none;
    margin: 0 20px;
}

.midiasFooter a img {
    width: 100%;
    max-width: 18px;
    margin-top: -3px;
    margin-right: 4px;
}

.boxLogoCity {
    background: #363636;
    padding: 15px 0;
}

.boxLogoCity img {
    width: 100%;
    max-width: 177px;
}

.copyright {
    color: #fff;
    font-size: 12px;
    padding: 18px 0;
}

.copyright p {
    margin-bottom: 0;
}

@media(max-width: 575px){
    .midiasFooter a {
        margin: 0 12px;
    }
}