@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;600;700&display=swap');

h1, h2, h3, h4, h5, h6, p, a, button {
    font-family: 'Work Sans', sans-serif;
}

a {
    color: #3a3a3a;
    text-decoration: none;
}

a:hover {
    color: #3a3a3a;
    text-decoration: none;
}

h3 {
    font-weight: 700;
    font-size: 25px;
    color: #3a3a3a;
}

@media (min-width: 1200px){
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 2000px;
    }
}

@media(min-width: 576px){
    .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
        padding-right: 25px;
        padding-left: 25px;
    }
}

.content {
    max-width: 800px;
    margin: 0 auto;
}

.boxTopNotice {
    display: flex;
    justify-content: space-between;
}

.categoryNotice span {
    font-size: 13px;
    font-weight: 700;
    color: #df0700;
}

.categoryNotice svg {
    color: #df0700;
    font-size: 18px;
    margin-left: 4px;
}

.dateNotice span {
    font-size: 13px;
}

.dateNotice span:nth-of-type(2) {
    color: #9daaaa;
    margin: 0 3px;
}

.boxNotice {
    margin-bottom: 35px;
}

.boxNotice h1 {
    font-size: 37px;
    font-weight: bold;
    color: #3a3a3a;
    line-height: 40px;
    margin-top: 2px;
    margin-bottom: 5px;
}

.boxNotice.small h1 {
    font-size: 22px;
    line-height: 25px;
}

.boxNotice a:hover {
    text-decoration: none;
}

.boxNotice p {
    font-size: 19px;
    color: #3a3a3a;
    line-height: 30px;
}

.boxNotice.small p {
    font-size: 14px;
    line-height: 19px;
}

.boxAdvisor {
    margin: 65px 0 15px;
}

img.bannerLogin {
    width: 100%;
    object-fit: contain;
}

img.bannerLoginMobile {
    width: 100%;
    object-fit: contain;
    height: 100%;
    object-position: left;
    display: none;
}

.btnMore {
    font-weight: bold;
    font-size: 20px;
    display: flex;
}

.order {
    float: right;
    margin-bottom: 40px;
    display: flex;
}

.order a {
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    margin-left: 15px;
}

a.allNotices, a.allNotices:hover, a.allNotices:focus {
    text-decoration: none;
    color: #3a3a3a;
    font-weight: bold;
    font-size: 20px;
    display: table;
    margin: 0 auto;
}

.css-1pahdxg-control, .css-1pahdxg-control:hover, .css-1pahdxg-control:focus, .css-yk16xz-control:focus {
    border-color: hsl(0, 0%, 80%);
    box-shadow: none;
}

.infinite-scroll-component  {
    overflow-x: hidden !important;
}

.fromeditor a, .fromeditor a span, .fromeditor a u {
    color: #df0700 !important;
}

@media screen and (max-width: 768px) {
    img.bannerLogin {
        display: none;
    }
    img.bannerLoginMobile {
        display: block;
    }
}