.box-titles {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    margin-top: 40px;
}

h1.title.active {
    position: relative;
    font-size: 29px;
    font-weight: 700;
    color: #363636;
}

.title.active::after {
    content: "";
    background-color: #df0700;
    width: 100px;
    height: 3px;
    bottom: -4px;
    left: 0px;
    position: absolute;
}

h1.title {
    color: #5c5c5c;
    font-size: 29px;
    font-weight: 400;
    margin-left: 60px;
    cursor: pointer;
}

h1.title:first-child {
    margin-left: 0;
}

.boxNotice img {
    width: 100%;
    margin-bottom: 10px;
}

.boxNotice.small.boletim h1 {
    font-size: 18px;
    line-height: 20px;
    margin-top: 5px;
}

.boxNotice.small.boletim p {
    font-size: 13px;
}

.css-2b097c-container {
    width: 100%;
    margin-left: 20px;
    margin-top: 23px;
    float: right;
}

.css-1pahdxg-control, .css-1pahdxg-control:hover, .css-1pahdxg-control:focus, .css-yk16xz-control:focus {
    border-color: #df0700 !important;
    box-shadow: 0 0 0 1px #df0700;
}

img.imgBoletim {
    width: 100%;
    object-fit: contain;
    height: 100%;
    object-position: left;
}

@media screen and (max-width: 768px) {
    .box-titles {
        display: table;
        margin-top: 20px;
    }
    h1.title {
        margin-left: 0;
    }
    .css-2b097c-container {
        margin-top: 0;
        margin-bottom: 23px;
    }
}